import type { LiteProducts } from '@fubotv/types/api/UP/products'

import { ApiError } from '~/types/domain'

import {
  FETCH_PRODUCTS_FAIL,
  FETCH_PRODUCTS_SUCCESS,
  FetchProductsFailAction,
  FetchProductsSuccessAction,
  TRY_FETCH_PRODUCTS,
  TryFetchProductsAction,
} from '../actionTypes/'

import { call } from '../../js-client'
import { doFetchProducts } from 'src/modules/reducers/products/effects'

export const tryFetchProducts = (): TryFetchProductsAction => {
  return async dispatch => {
    try {
      const data = await call(doFetchProducts({visibilityContext: 'store'}))
      console.log(data)
      await dispatch(fetchProductsSuccess(data))
    } catch (error) {
      if (error.response) {
        dispatch(fetchProductsFail(error.response))
      } else if (error.request) {
        dispatch(fetchProductsFail(error.request))
      } else {
        dispatch(fetchProductsFail(error.message))
      }
    }
  }
}

export const fetchProductsSuccessful = ({
  products: plans,
  addons: addOns = [],
}: LiteProducts): FetchProductsSuccessAction => ({
  type: FETCH_PRODUCTS_SUCCESS,
  plans,
  addOns,
})

export const fetchProductsFail = (
  error: ApiError
): FetchProductsFailAction => ({
  type: FETCH_PRODUCTS_FAIL,
  error,
})
