import React from 'react'
import { getUniqueIdCookie } from '@fubotv/cookies'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectAnalyticsReady,
  updateAnalyticsProperties,
  updateAnalyticsReady,
  updateParameters,
} from 'src/modules/reducers/analytics'
import { call } from 'src/modules/reducers/api-client'
import { getLocation } from '@fubotv/api-client/lib/domains/location'
import { track } from 'src/modules/analytics/track'
import Bowser from 'bowser'
import { nanoid } from 'nanoid'
import { updateMarket, selectCountryCode, selectMarketState } from 'src/modules/reducers/market'
import { parseQueryParams } from 'src/modules/utils/parseQueryParams'
import { fetchPlans } from 'src/modules/reducers/plans/actions'
import { updateParams } from 'src/modules/reducers/params'
import initAnalytics from 'src/modules/analytics/vendor'
import { tryFetchProducts } from 'src/modules/reducers/products/actions'

const Analytics = ({ lang, market }) => {
  const dispatch = useDispatch()

  // Get geolocation data
  const fetchGeolocation = async () => {
    try {
      const res = await call(getLocation)
      if (res) {
        const data = {
          countryCode: res.countryCode,
          countryCode2: res.countryCode2,
          countryName: res.countryName,
          dma: res.dma,
          postalCode: res.postalCode,
        }
        return data
      }
    } catch (error) {
      if (error.response) {
        console.log('location error', error.response)
      } else if (error.request) {
        console.log('location error', error.request)
      } else {
        console.log('location error', error.message)
      }
    }
  }

  // Initialize Analytics
  const initAnalyticsPackage = () => {
    initAnalytics()
    window.analytics &&
      window.analytics.ready &&
      window.analytics.ready(() => {
        dispatch(updateAnalyticsReady())

        const amplitudeInstance = window.amplitude && window.amplitude.getInstance()
        const amplitudeDeviceId =
          amplitudeInstance && amplitudeInstance.options && amplitudeInstance.options.deviceId
        const uniqueId = amplitudeDeviceId || getUniqueIdCookie()
        if (uniqueId) {
          window.analytics.user().anonymousId(uniqueId)
        }
      })
  }

  // Send page view
  const sendPageView = async () => {
    let geolocation = null
    try {
      geolocation = await fetchGeolocation()
    } catch (error) {
      console.log('error fetching geolocation', error)
    } finally {
      const { navigator, document, location, screen } = window
      const browser = Bowser.getParser(window.navigator.userAgent)
      var language = window.navigator.userLanguage || window.navigator.language
      const queryParams = parseQueryParams(location.href)

      const updatedAnalyticsProperties = {
        data: {
          device_context: {
            user_agent: navigator?.userAgent,
            os: browser?.getOSName(),
            browser: browser?.getBrowserName(),
            screen_height: screen?.height,
            screen_width: screen?.width,
            device_name: browser?.getBrowserName() + ' | ' + browser.getOSName(),
          },
          event_context: {
            ...(document?.referrer && { referrer: document?.referrer }),
            url: location?.href,
            page: 'stream_landing_page',
          },
          location_context: {
            country_code: geolocation?.countryCode2,
            dma: geolocation?.dma,
            postal_code: geolocation?.postalCode,
          },
        },
        device_category:
          browser?.getPlatformType() === 'mobile' || browser?.getPlatformType() === 'tablet'
            ? 'mobile_web'
            : 'web',
        event_category: 'user_action',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        spec_version: '3.24.0',
        event_metadata: {
          referrer: document.referrer,
          url: location.href,
          domain: location.hostname,
          url_slug: location.pathname.substring(1, location.pathname.length - 1),
          language: language,
        },
        lp_context: {
          lang: lang,
          market: market,
        },
        url_params: {
          offer: queryParams?.offer,
          irad: queryParams?.irad,
          irmp: queryParams?.irmp,
          sharedid: queryParams?.sharedid,
          page_slug: queryParams?.page_slug,
          'addons[]': queryParams?.['addons[]'],
        },
        ...(queryParams?.ftv_campaign && { ftv_campaign: queryParams?.ftv_campaign }),
      }

      track('page_view', updatedAnalyticsProperties)
      if (window && window?.dataLayer) {
        window?.dataLayer.push({ event: 'page_view', ...updatedAnalyticsProperties })
      }
      dispatch(updateMarket(geolocation))
      dispatch(updateAnalyticsProperties(updatedAnalyticsProperties))
    }
  }

  const location = typeof window !== 'undefined' && window.location

  React.useEffect(() => {
    initAnalyticsPackage()
    dispatch(fetchPlans())
    dispatch(tryFetchProducts())
  }, [])

  // Get request query params to save into store
  React.useEffect(() => {
    let queryParams = parseQueryParams(location.href)
    const referrer = window?.document?.referrer
    if (Object.keys(queryParams).length > 0) {
      if (referrer.includes('.google.') || referrer.includes('.bing.')) {
        if (queryParams.irad) {
          delete queryParams.irad
          console.log('removing irad', queryParams.irad)
        }
        if (queryParams.irmp) {
          delete queryParams.irmp
          console.log('removing irmp', queryParams.irmp)
        }
      }
      dispatch(updateParameters(queryParams))
      dispatch(updateParams(queryParams))
    }
  }, [location])

  // once analytics is loaded and read, send page view
  const analyticsReady = useSelector(selectAnalyticsReady)
  React.useEffect(() => {
    if (typeof window !== 'undefined' && window.analytics && analyticsReady) {
      sendPageView()
    }
  }, [analyticsReady])

  return null
}

export default Analytics
